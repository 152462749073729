var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "825" },
          on: { "click:outside": _vm.close },
          model: {
            value: _vm.showReleaseNotesLatest,
            callback: function($$v) {
              _vm.showReleaseNotesLatest = $$v
            },
            expression: "showReleaseNotesLatest"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title grey lighten-2",
                  attrs: { "primary-title": "" }
                },
                [_vm._v("\n        Release Notes\n      ")]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "black--text pt-5",
                  staticStyle: { height: "500px" }
                },
                [
                  _c(
                    "p",
                    { staticClass: "font-italic" },
                    [
                      _vm._v(
                        "\n          To see all release notes click\n          "
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/releaseNotes" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("\n            here.\n          ")]
                      )
                    ],
                    1
                  ),
                  _c("DisplayReleaseNotes", { attrs: { "show-latest": "" } })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "success" }, on: { click: _vm.close } },
                    [_vm._v("\n          Ok\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }