<template>
  <div>
    <v-dialog
      v-model="showReleaseNotesLatest"
      scrollable
      width="825"
      @click:outside="close"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          Release Notes
        </v-card-title>

        <v-card-text
          class="black--text pt-5"
          style="height: 500px;"
        >
          <p class="font-italic">
            To see all release notes click
            <router-link
              to="/releaseNotes"
              @click="close"
            >
              here.
            </router-link>
          </p>
          <DisplayReleaseNotes show-latest />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="close"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import '@/plugins/vue-froala-wysiwyg'
import { mapActions } from 'vuex'
// import { azBlobService } from '@/shared/services'

export default {
  components: {
    DisplayReleaseNotes: () => import('@/views/pages/components/help/DisplayReleaseNotes')
  },

  // data: () => ({
  //   editorData: null
  // }),

  computed: {
    showReleaseNotesLatest: {
      get () {
        return this.$store.getters['auth/areReleaseNotesSeenLatest']() === false
      }
    }
  },

  methods: {
    ...mapActions('auth', ['seeReleaseNotes']),
    async close () {
      await this.seeReleaseNotes({ userId: this.$store.getters['auth/getUserId']() })
    }
  //   formatReleases (resp) {
  //     var _this = this
  //     this.editorData = resp.reduce(function (str, elem) {
  //       return str + `<h2>${elem.version} - ${_this.$options.filters.displayDateFormat(elem.releaseDate)}</h2>
  //       <br />
  //         <ul>
  //           ${elem.bullets.reduce((str2, val) => {
  //   return str2 + `<li>${val}</li>`
  // }, '')}
  //         </ul>
  //         <br />
  //         <br />`
  //     }, '')
  //   }
  }

  // watch: {
  //   showReleaseNotesLatest: {
  //     immediate: true,
  //     handler: function (newValue, oldValue) {
  //       if (newValue || (newValue === undefined && oldValue)) {
  //         azBlobService.getReleaseNotes().then(resp => {
  //           if (resp.errorResponse) {
  //             this.$store.commit('setGlobalSnackbar', {
  //               message: 'Could not get latest release notes',
  //               color: 'error'
  //             })
  //           } else {
  //             this.formatReleases(resp)
  //           }
  //         })
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
// .ck-editor__editable_inline {
//   max-height: 200px;
// }
// .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
//   border: none !important;
// }
// .ck.ck-reset_all {
//   height: 0% !important;
// }
</style>
